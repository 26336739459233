<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Organizações">
      <datatable-list
        :text="text"
        :headers="headers"
        :items="organizations"
        v-if="isDataLoaded"
        :displayActionItems="false"
        :emit="true"
        @select="setOrganization($event)"
      ></datatable-list>
      <v-skeleton-loader
        v-else
        class="mb-6"
        :boilerplate="true"
        :elevation="2"
        v-for="i in 5"
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      }
    ],
    text: {
      emptyLabel: 'Nenhuma organização disponível'
    },
    headers: [
      { text: 'Organização', value: 'data', align: 'start' }
    ]
  }),

  computed: {
    ...mapGetters('organization', ['organizations', 'activeOrganization']),
    isDataLoaded () {
      return !!this.organizations.length
    }
  },

  methods: {
    ...mapMutations('organization', ['SET_ACTIVE_ORGANIZATION']),
    ...mapMutations('exam', ['SET_STARTED_NOT_STARTED_EXAMS_LIST']),
    setOrganization (org) {
      this.SET_ACTIVE_ORGANIZATION(org.metadata.id)
      this.SET_STARTED_NOT_STARTED_EXAMS_LIST({
        items: [],
        pagination: {
          current_page: 1,
          last_page: 0,
          total: 0
        }
      })
      localStorage.setItem('ACTIVE_ORGANIZATION', org.metadata.id)
      this.$router.push({ path: '/resolution/list' })
    }
  },

  async mounted () {
    if (!this.organizations.length) {
      await this.$store.dispatch('organization/fetchOrganizations')
    }

    /* Se não tiver nenhuma organização selecionada,
    e tiver apenas uma organização, seleciona ela automaticamente */
    if (!this.activeOrganization) {
      if (this.organizations.length === 1) {
        this.setOrganization(this.organizations[0])
        return
      }
      this.$store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma organização' })
    }
  }
}
</script>
